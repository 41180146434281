import React from 'react'

import { Header } from 'components/Header'
import HtmlParser from 'components/HtmlParser'
import Seo from 'components/seo'

import * as cls from './aboutUs.module.scss'

const AboutPage = ({ pageContext: { pageData } }) => {
  return (
    <>
      <Seo title={pageData.title} meta={pageData.seo} />
      <Header
        h1={pageData.header.h1}
        breadcrumbs={[{
          title: pageData.title,
        }]}
        isWide
      />

      <section className={cls.about__page}>
        <div className="container">
          <h2 className={cls.about__page_title}>
            {pageData.contentTitle}
          </h2>

          <div className={cls.about__page_text}>
            <HtmlParser htmlString={pageData.content} />
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutPage
